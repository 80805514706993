import auth from "./auth";

const GRAPH_BASE = "https://graph.microsoft.com/v1.0";
//const GRAPH_SCOPES = ["user.read", "user.readbasic.all"];
const GRAPH_SCOPES = ["user.read"];

let accessToken;

export default {
  //
  // Get profile of logged on user
  //
  async getMe() {
    let resp = await callGraph("/me");
    if (resp) {
      let data = await resp.json();
      return data;
    }
  },

  //
  // Get photo as blob
  //
  async getPhoto() {
    let resp = await callGraph("/me/photo/$value");
    if (resp) {
      let blob = await resp.blob();
      return URL.createObjectURL(blob);
    }
  },

  //
  // SearchUsers
  //
  async searchUsers(searchString, max = 50) {
    let resp = await callGraph(
      `/users?$filter=startswith(displayName, '${searchString}') or startswith(userPrincipalName, '${searchString}')&$top=${max}`
    );
    if (resp) {
      let data = await resp.json();
      return data;
    }
  },

  getAccessToken() {
    return accessToken;
  },
};

//
// Common fetch wrapper (private)
//
async function callGraph(apiPath) {
  // Acquire an access token to call APIs (like Graph)
  // Safe to call repeatedly as MSAL caches tokens locally

  accessToken = await auth.acquireToken(GRAPH_SCOPES);

  let resp = await fetch(`${GRAPH_BASE}${apiPath}`, {
    headers: { authorization: `Bearer ${accessToken}` },
  });

  if (!resp.ok) {
    throw new Error(
      `Call to ${GRAPH_BASE}${apiPath} failed: ${resp.statusText}`
    );
  }

  return resp;
}
