<template>
  <div>
    <v-container class="pa-5" fluid>
      <v-row>
        <v-col xs="12" sm="12" md="6" cols="12">
          <HostPoolsCombobox
            @update="selectedHostPools = $event"
            :disabled="userSessionsLoading"
            multiple
          />
        </v-col>
        <v-col xs="12" sm="12" md="6" col="12">
          <v-btn
            large
            color="green"
            dark
            @click="getUserSessions"
            :loading="userSessionsLoading"
            :disabled="userSessionsLoading"
            class="elevation-2"
          >
            Refresh Userdata
          </v-btn>
          <v-btn
            large
            color="orange"
            dark
            class="elevation-2"
            v-if="userSessionsLoading"
            @click="cancelationRequested = true"
          >
            Stop
          </v-btn>
        </v-col>
      </v-row>
      <v-data-table
        v-if="userSessions"
        :headers="headers"
        :items="userSessions"
        :loading="userSessionsLoading"
        :search="searchBarText"
        single-expand
        class="mt-5 elevation-2"
        @click:row="(item, slot) => logExpand(item, slot)"
      >
        <template v-slot:top>
          <v-text-field
            v-model="searchBarText"
            class="mx-4"
            prepend-inner-icon="mdi-magnify"
          />
          <v-dialog v-model="dialogLogout" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Are you sure you want to logout this user?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialogLogout = false"
                  >Cancel</v-btn
                >
                <v-btn color="blue darken-1" text @click="logoutUserConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogRemoveUserSession" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Are you sure you want to remove this user
                session?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="dialogRemoveUserSession = false"
                  >Cancel</v-btn
                >
                <v-btn
                  color="blue darken-1"
                  text
                  @click="removeUserSessionConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :loading="item.isRefreshing"
                color="green"
                outlined
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon @click.stop="refreshUserEntry(item)">
                  mdi-refresh
                </v-icon>
              </v-btn>
            </template>
            <span>Refresh</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :loading="item.isLoggingOut"
                class="mx-2"
                color="orange"
                outlined
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon @click.stop="showLogoutDialog(item)">
                  mdi-logout-variant
                </v-icon>
              </v-btn>
            </template>
            <span>Logout</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :loading="item.isRemovingUserSession"
                color="red"
                outlined
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon @click.stop="showRemoveUserSessionDialog(item)">
                  mdi-account-remove
                </v-icon>
              </v-btn>
            </template>
            <span>Remove User Session</span>
          </v-tooltip>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <div class="py-2">
              <v-data-table
                :items="item.logData"
                :headers="logDataHeaders"
                disable-pagination
                hide-default-footer
                dense
                dark
                class="primary"
              >
                <!--
                <template v-slot:[`item.TimeGenerated`]="{ item }">
                  {{ item.TimeGenerated | formatDate }}
                </template>
                -->
              </v-data-table>
            </div>
          </td>
        </template>
        <template v-slot:[`item.expandicon`]="">
          <v-icon large> mdi-chevron-right </v-icon>
        </template>

        <!--
        <template v-slot:[`item.properties.createTime`]="{ item }">
          {{ item.properties.createTime | formatDate }}
        </template>
        -->
      </v-data-table>
    </v-container>
  </div>
</template>

<script>
import HostPoolsCombobox from "./HostPoolsCombobox.vue";
import azvirtualdesktop from "../Services/azure-virtualdesktop";
import log from "../Services/log";
import azureLoganalytics from "../Services/azure-loganalytics";
export default {
  name: "VdiUserSessions",
  components: {
    HostPoolsCombobox,
  },
  data: () => ({
    searchBarText: "",
    userSessions: [],
    selectedUserSessions: [],
    selectedHostPools: [],
    cancelationRequested: false,
    expandedUser: [],
    dialogLogout: false,
    dialogRemoveUserSession: false,
    actualLogoutItem: new Object(),
    actualRemoveSessionItem: new Object(),

    headers: [
      { text: "", value: "expandicon", width: 20 },
      { text: "HostName", value: "name" },
      { text: "SessionCreateTime", value: "properties.createTime" },
      { text: "User", value: "properties.userPrincipalName" },
      { text: "SessionState", value: "properties.sessionState" },
      { text: "ApplicationType", value: "properties.applicationType" },
      { text: "ADUserName", value: "properties.activeDirectoryUserName" },
      { text: "Actions", value: "actions", sortable: false },
    ],

    logDataHeaders: [
      { text: "TimeGenerated", value: "TimeGenerated" },
      { text: "UserName", value: "UserName" },
      { text: "State", value: "State" },
      { text: "ClientOS", value: "ClientOS" },
      { text: "ClientVersion", value: "ClientVersion" },
      { text: "ClientType", value: "ClientType" },
      { text: "ClientIPAddress", value: "ClientIPAddress" },
      { text: "ConnectionType", value: "ConnectionType" },
      { text: "SessionHostIPAddress", value: "SessionHostIPAddress" },
      { text: "SessionHostOSVersion", value: "SessionHostOSVersion" },
      { text: "SessionHostOSDescription", value: "SessionHostOSDescription" },
      { text: "GatewayRegion", value: "GatewayRegion" },
    ],
    userSessionsLoading: false,
  }),

  methods: {
    ////////////////////////
    // GetUserSessions
    async getUserSessions() {
      this.userSessionsLoading = true;
      this.userSessions = [];
      for (var hostPool of this.selectedHostPools) {
        if (this.cancelationRequested) break;
        let hosts = await azvirtualdesktop.getSessionHostsWithPath(hostPool.id);
        for (var host of hosts) {
          if (host.properties.sessions == 0) {
            log.Info("unavalable springe");
            continue;
          }

          if (this.cancelationRequested) break;
          let users = (await azvirtualdesktop.getUserSessionsWithPath(host.id))
            .value;
          users.forEach((item) => {
            item.isRefreshing = false;
            item.isLoggingOut = false;
            item.isRemovingUserSession = false;
            item.logData = [];
            item.properties.createTime = this.formatDate(
              item.properties.createTime
            );
          });
          this.userSessions.push(...users);
        }
      }

      this.userSessionsLoading = false;
      this.cancelationRequested = false;
    },

    ///////////////////////////
    // Refresh User entry
    async refreshUserEntry(item) {
      item.isRefreshing = true;
      let itemIndex = this.userSessions.indexOf(item);
      azvirtualdesktop
        .getUserSessionWithPath(item.id)
        .then((refreshedItem) => {
          refreshedItem.properties.createTime = this.formatDate(
            refreshedItem.properties.createTime
          );
          refreshedItem.isRefreshing = false;
          Object.assign(this.userSessions[itemIndex], refreshedItem);
        })
        .catch((error) => {
          log.Error("refresh failed", error);
          log.Action("removing item");
          this.userSessions.splice(this.userSessions[itemIndex], 1);
        });
    },

    ///////////////////////
    // Show logout dialog
    async showLogoutDialog(item) {
      if (item.properties.sessionState == "Active") {
        this.dialogLogout = true;
        this.actualLogoutItem = item;
      }
    },
    ///////////////////////
    // Show remove user session dialog
    async showRemoveUserSessionDialog(item) {
      this.dialogRemoveUserSession = true;
      this.actualRemoveSessionItem = item;
    },

    ///////////////////////
    // do the logout
    async logoutUserConfirm() {
      this.actualLogoutItem.isLoggingOut = true;
      let itemIndex = this.userSessions.indexOf(this.actualLogoutItem);
      log.Info(`index is: '${itemIndex}'`);

      try {
        await azvirtualdesktop.disconnectUserSession(this.actualLogoutItem.id);
        this.actualLogoutItem.isLoggingOut = false;
        Object.assign(this.userSessions[itemIndex], this.actualLogoutItem);
      } catch (error) {
        log.Error("logout failed", error);
      }
      this.dialogLogout = false;
      await this.refreshUserEntry(this.actualLogoutItem);
      this.actualLogoutItem = null;
    },

    ///////////////////////
    // do remove user session
    async removeUserSessionConfirm() {
      this.actualRemoveSessionItem.isRemovingUserSession = true;
      let itemIndex = this.userSessions.indexOf(this.actualRemoveSessionItem);
      try {
        await azvirtualdesktop.removeUserSession(
          this.actualRemoveSessionItem.id
        );
        this.actualRemoveSessionItem.isRemovingUserSession = false;
        this.userSessions.splice(itemIndex, 1);
      } catch (error) {
        log.Error("removing user session failed", error);
        await this.refreshUserEntry(this.actualRemoveSessionItem);
      }

      this.dialogRemoveUserSession = false;
      this.actualRemoveSessionItem = null;
    },

    ///////////////////////////////////
    // Load expand data and expand
    async logExpand(item, slot) {
      //this.expandedUser = [];
      //let itemIndex = this.userSessions.indexOf(item);
      //log.Info(itemIndex);
      item.logData = [];
      if (!slot.isExpanded) {
        let search =
          item.id.substring(0, item.id.indexOf("sessionhosts")) + "query";
        log.Action("loading Loganalytics");
        azureLoganalytics
          .GetLastConnectionsByUser(
            search,
            item.properties.userPrincipalName,
            5
          )
          .then((result) => {
            result.forEach((item) => {
              item.TimeGenerated = this.formatDate(item.TimeGenerated);
            });
            item.logData = result;
          })
          .catch((error) => {
            log.Error("Error expanding user", error);
          });
      }
      slot.expand(!slot.isExpanded);
      //item.isExpanded = !item.isExpanded;
    },
  },

  async created() {
    log.Info("Create ended");
  },
};
</script>
<style>
#app {
  background-color: var(--v-primary-darken1) !important;
}
</style>
