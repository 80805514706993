import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import Emitter from "tiny-emitter";
import log from "./Services/log";
import moment from "moment";
import VueApexCharts from "vue-apexcharts";
import store from "./store";

Vue.config.productionTip = false;
Vue.prototype.$emitter = new Emitter();
Vue.prototype.$config = {
  auth: {
    clientId: "501afc72-3aeb-411f-8438-41baaf4addc0",
    redirectUri: window.location.origin,
    authority:
      "https://login.microsoftonline.com/0df119c8-23ea-40d9-8dda-62d4a4db8dfb",
  },
  cache: {
    cacheLocation: "localStorage",
  },
};

Vue.mixin({
  methods: {
    formatDate: function(datevalue) {
      return moment(datevalue)
        .local()
        .format("DD.MM.YYYY HH:mm:ss");
    },
    formatDateChooser: function(value) {
      return value ? moment(value).format("dd, MMM Do YYYY") : "";
    },
  },
});

Vue.use(VueApexCharts);
Vue.use(store);
Vue.component("apexchart", VueApexCharts);

log.TitleBar("VDI-Manager");
log.Action("Mounting vue instance");
new Vue({
  vuetify,
  store,
  render: (h) => h(App),
}).$mount("#app");
