import auth from "./auth";
import log from "./log";

const AZURE_LOGANALYTICS_BASE = "https://api.loganalytics.io/v1";
const AZURE_LOGANALYTIC_SCOPES = ["https://api.loganalytics.io/Data.Read"];

//const AZURE_LOGANALYTICS_BASE = "https://management.azure.com";
//const AZURE_LOGANALYTIC_SCOPES = [
//  "https://management.core.windows.net//user_impersonation",
//];

let accessToken;

export default {
  // for test only
  async GetAllNikeWVDErrors() {
    let resp = await InvokeQuery(
      "/subscriptions/e54b1514-58c9-4b05-8957-65ccac852435/resourceGroups/rg-vdi-nike/providers/Microsoft.DesktopVirtualization/hostpools/Nike-Global/query",
      //"/subscriptions/e54b1514-58c9-4b05-8957-65ccac852435/resourceGroups/rg-vdi-nike/providers/Microsoft.OperationalInsights/hostpools/Nike-Global/query",
      "WVDErrors | where TimeGenerated > ago(2d)"
    );

    if (resp) {
      let data = await resp.json();
      return ConvertToObjectArray(data);
    }
  },
  /*
  async GetAllErrorsForHostPool(hostpoolPath, days) {
    let queryPath = hostpoolPath + "/query";
    log.Info("Path is " + queryPath);
    let resp = await InvokeQuery(
      queryPath,
      `WVDErrors | where TimeGenerated > ago(${days}d)`
    );

    if (resp) {
      let data = await resp.json();
      return ConvertToObjectArray(data);
    }
  },
*/
  async GetAllErrorsForHostPool(hostpoolPath, startDate, endDate) {
    let queryPath = hostpoolPath + "/query";
    log.Info("Path is " + queryPath);
    let query = "";
    if (startDate && endDate) {
      query = `WVDErrors | where TimeGenerated between (datetime(${startDate}) .. datetime_add('day',1,datetime(${endDate})))`;
    } else if ((startDate && !endDate) || startDate == endDate) {
      query = `WVDErrors | where TimeGenerated between (datetime(${startDate}) .. datetime_add('day',1,datetime(${startDate})))`;
    } else if (!startDate && endDate) {
      query = `WVDErrors | where TimeGenerated between (datetime(${endDate}) .. datetime_add('day',1,datetime(${endDate})))`;
    }

    query += "| sort by TimeGenerated asc";
    //query += "| top 2000 by TimeGenerated asc";

    log.Info(`query is: ${query}`);

    let resp = await InvokeQuery(queryPath, query);

    if (resp) {
      let data = await resp.json();
      return ConvertToObjectArray(data);
    }
  },

  async GetAllConnectionLogsForHostPool(hostpoolPath, startDate, endDate) {
    let queryPath = hostpoolPath + "/query";
    log.Info("Path is " + queryPath);
    let query = "";
    if (startDate && endDate) {
      query = `WVDConnections | where TimeGenerated between (datetime(${startDate}) .. datetime_add('day',1,datetime(${endDate})))`;
    } else if ((startDate && !endDate) || startDate == endDate) {
      query = `WVDConnections | where TimeGenerated between (datetime(${startDate}) .. datetime_add('day',1,datetime(${startDate})))`;
    } else if (!startDate && endDate) {
      query = `WVDConnections | where TimeGenerated between (datetime(${endDate}) .. datetime_add('day',1,datetime(${endDate})))`;
    }

    query += "| sort by TimeGenerated asc";
    //query += "| top 2000 by TimeGenerated asc";

    log.Info(`query is: ${query}`);

    let resp = await InvokeQuery(queryPath, query);

    if (resp) {
      let data = await resp.json();
      return ConvertToObjectArray(data);
    }
  },

  async GetLastConnectionsByUser(path, username, num) {
    log.Info(`ApiPath is:${path}`);
    log.Info(
      `Query is: WVDConnections | where UserName contains "${username}" | order by TimeGenerated | take ${num}`
    );
    let resp = await InvokeQuery(
      path,
      `WVDConnections | where UserName contains "${username}" | order by TimeGenerated | take ${num}`
    );

    if (resp) {
      let data = await resp.json();
      return ConvertToObjectArray(data);
    }
  },

  async GetActiveSessionsForPeriod(workspaceId, deviceFilter, days) {
    log.Info(`WorkspaceId is ${workspaceId}`);
    log.Info(`DeviceFilter is ${deviceFilter}`);
    let path = `/workspaces/${workspaceId}/query`;     
    let filter = deviceFilter ? `and  Computer contains "${deviceFilter}"` : ""
    let query = 
    `Perf
    | where TimeGenerated > ago(${days}d) and (CounterName == "Active Sessions" or CounterName == "Aktive Sitzungen") ${filter}
    | project TimeGenerated,Computer,CounterValue
    | order by TimeGenerated asc
    `  
    log.Info(`Query is: ${query}`)  
    let resp = await InvokeQuery(
      path,
      query
    );

    if (resp) {
      let data = await resp.json();
      let convertedData = ConvertToObjectArray(data);
      return convertedData;
    }
  },
  async GetCpuUsageForPeriod(workspaceId, deviceFilter, days) {
    log.Info(`WorkspaceId is ${workspaceId}`);
    log.Info(`DeviceFilter is ${deviceFilter}`);
    let path = `/workspaces/${workspaceId}/query`;
    let filter = deviceFilter ? `and  Computer contains "${deviceFilter}"` : ""
    let query =
    `Perf
      | where TimeGenerated > ago(${days}d) and ((CounterName == '% Processor Time' or CounterName == 'Prozessorzeit (%)') and InstanceName == "_Total") ${filter}
      | summarize avg(CounterValue) by Computer, bin(TimeGenerated, 5m)      
      | order by TimeGenerated asc
      | project TimeGenerated,Computer,CounterValue = avg_CounterValue
      ` 
    log.Info(`Query is: ${query}`)  
    let resp = await InvokeQuery(
      path,
      query
      
    );

    if (resp) {
      let data = await resp.json();
      let convertedData = ConvertToObjectArray(data);
      return convertedData;
    }
  },
  async GetMemoryUsageForPeriod(workspaceId, deviceFilter, days) {
    log.Info(`WorkspaceId is ${workspaceId}`);
    log.Info(`DeviceFilter is ${deviceFilter}`);
    let path = `/workspaces/${workspaceId}/query`;
    let filter = deviceFilter ? `and  Computer contains "${deviceFilter}"` : ""
    let query =
    `Perf
      | where TimeGenerated > ago(${days}d) and (ObjectName == "Memory" or ObjectName == "Arbeitsspeicher") and (CounterName == '% Committed Bytes In Use' or CounterName == 'Zugesicherte verwendete Bytes (%)') ${filter} 
      | summarize avg(CounterValue) by Computer, bin(TimeGenerated, 5m)     
      | order by TimeGenerated asc
      | project TimeGenerated,Computer,CounterValue = avg_CounterValue
      `
    log.Info(`Query is: ${query}`)  
    let resp = await InvokeQuery(
      path,
      query
    );

    if (resp) {
      let data = await resp.json();
      let convertedData = ConvertToObjectArray(data);
      return convertedData;
    }
  },

  async GetMaxUsersForHostpool(workspaceId, deviceFilter, days) {
    log.Info(`MaxUsersQuery${days}`);
    log.Info(`WorkspaceId is ${workspaceId}`);
    log.Info(`DeviceFilter is ${deviceFilter}`);
    let path = `/workspaces/${workspaceId}/query`;
    let filter = deviceFilter ? `and  Computer contains "${deviceFilter}"` : ""
    let query =
    `Perf
      | where TimeGenerated > ago(${days}d) and (CounterName == "Active Sessions" or CounterName == "Aktive Sitzungen") ${filter}
      | summarize max(CounterValue) by Computer
      | project Computer, CounterValue = max_CounterValue
      `
    let resp = await InvokeQuery(
      path,
      query
    );

    if (resp) {
      let data = await resp.json();
      let convertedData = ConvertToObjectArray(data);
      return convertedData;
    }
  },
};

//////////////////////////////////////////////////////
// Private functions ///////////
//

async function InvokeQuery(apiPath, querystring) {
  log.Info("InvokeQuery -- getting token");
  //log.Info(JSON.stringify(querystring));
  accessToken = await auth.acquireToken(AZURE_LOGANALYTIC_SCOPES);
  //log.WriteObjectInfo(accessToken);

  let resp = await fetch(`${AZURE_LOGANALYTICS_BASE}${apiPath}`, {
    method: "post",
    headers: {
      authorization: `Bearer ${accessToken}`,
      accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      query: querystring,
    }),
  });

  if (!resp.ok) {
    throw new Error(
      `Call to ${AZURE_LOGANALYTICS_BASE}${apiPath} failed: ${resp.statusText}`
    );
  }
  return resp;
}

function ConvertToObjectArray(data) {
  let columns = data.tables[0].columns.map((column) => {
    return column.name;
  });
  let rows = data.tables[0].rows;
  let result = rows.map((row) => {
    return columns.reduce(
      (object, curr, i) => ((object[curr] = row[i]), object),
      {}
    );
  });

  return result;
}
