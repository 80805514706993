<template>
  <div>
    <v-container class="pa-5" fluid>
      <v-row>
        <v-col xs="12" sm="12" md="5" cols="12">
          <HostPoolsCombobox
            @update="
              selectedHostPool = $event;
              UpdateData();
            "
            :disabled="isRefreshing"
          />
        </v-col>
        <v-col xs="12" sm="12" md="5" col="12" class="py-0">
          <v-layout align-center>
            <v-btn
              large
              color="green"
              dark
              @click="UpdateData"
              :loading="isRefreshing"
              :disabled="isRefreshing"
              class="elevation-2"
            >
              Refresh
            </v-btn>

            <v-switch
              v-model="useMachineIdentifierTag"
              label="Use Machine Identifier Tag"
              color="white"
              dark
              inset
              class="px-5"
            ></v-switch>
          </v-layout>
        </v-col>
      </v-row>
      <v-row>
        <v-col xs="12" sm="12" md="5" cols="12">
          <apexchart
            type="bar"
            width="100%"
            height="250"
            :options="maxUserChartOptions"
            :series="maxUserSeries"
          ></apexchart>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="1" lg="1" xl="1" cols="4">
          <v-select
            v-model="daysSelect"
            :items="daysPickerItems"
            solo
            label="Pick days range for statistics"
            :disabled="isRefreshing"
          ></v-select>
        </v-col>
        <v-col cols="12">
          <apexchart
            :options="chartOptions"
            :series="activeSessionsSeries"
            height="350"
          ></apexchart>
          <apexchart
            :options="{
              ...chartOptions,
              ...{
                title: { text: 'Cpu usage in percent by VDI', align: 'left' },
              },
            }"
            :series="cpuUsageSeries"
            height="350"
          ></apexchart>
          <apexchart
            :options="{
              ...chartOptions,
              ...{
                title: {
                  text: 'Memory usage in percent by VDI',
                  align: 'left',
                },
              },
            }"
            :series="memoryUsageSeries"
            height="350"
          ></apexchart>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar
      v-model="snackbarIsVisible"
      multi-line
      :timeout="snackbarTimeOut"
    >
      <h4 class="red--text text--lighten-1">Error</h4>
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="snackbarIsVisible = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import HostPoolsCombobox from "./HostPoolsCombobox.vue";
import azloganalytics from "../Services/azure-loganalytics";
import log from "../Services/log";
import azureVirtualdesktop from "../Services/azure-virtualdesktop";
import dtHelper from "../Services/datatransformhelper";
//import azureLoganalytics from "../Services/azure-loganalytics";
//import moment from "moment";

export default {
  name: "VdiStatistics",
  components: {
    HostPoolsCombobox,
  },
  data: () => ({
    snackbarIsVisible: false,
    snackbarText: "",
    snackbarTimeOut: 3000,
    isRefreshing: false,
    selectedHostPool: null,
    daysSelect: 3,
    daysPickerItems: [3, 7, 14],
    useMachineIdentifierTag: true,
    chartOptions: {
      chart: {
        id: "ActiveSessionsChart",
        type: "line",
        height: 350,
        background: "#fff",
        zoom: {
          enabled: false,
        },
      },
      stroke: {
        curve: "straight",
        width: 2,
      },
      xaxis: {
        type: "datetime",
      },
      tooltip: {
        x: {
          format: "dd/MM/yy HH:mm",
        },
      },
      title: {
        text: "Active sessions by VDI",
        align: "left",
      },
      dataLabels: {
        enabled: false,
      },
      grid: {
        row: {
          borderColor: "#f1f1f1",
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5,
        },
      },
    },

    activeSessionsSeries: [
      {
        name: "none",
        data: [],
      },
    ],
    cpuUsageSeries: [
      {
        name: "none",
        data: [],
      },
    ],
    memoryUsageSeries: [
      {
        name: "none",
        data: [],
      },
    ],

    // 30 days MaxUsers BarChart
    maxUserSeries: [
      {
        data: [],
      },
    ],
    maxUserChartOptions: {
      chart: {
        //width: "25%",
        height: 250,
        type: "bar",
        background: "#fff",
      },
      //colors: colors,
      plotOptions: {
        bar: {
          distributed: true,
          horizontal: false,
          dataLabels: {
            position: "top",
          },
        },
      },
      title: {
        text: "Max Users within 30 days",
        align: "left",
      },
      dataLabels: {
        dataLabels: {
          enabled: true,
        },
      },

      yaxis: {
        labels: {
          show: true,
        },
      },
      xaxis: {
        type: "category",
        labels: {
          show: false,
        },
      },
    },
  }),
  methods: {
    async RefreshCharts() {
      window.dispatchEvent(new Event("resize"));
    },
    async UpdateData() {
      //console.log(this.selectedHostPool);
      if (this.selectedHostPool) {
        this.isRefreshing = true;
        let hostPoolPath = this.selectedHostPool.id;
        let machineIdentifier = this.useMachineIdentifierTag
          ? this.selectedHostPool.tags.MachineIdentifier
          : null;

        log.Info(`Path: ${hostPoolPath}`);
        log.Info(`MachineIdentifier: ${machineIdentifier}`);

        if (!machineIdentifier && this.useMachineIdentifierTag) {
          log.Error(`no machineIdentifier provided for ${hostPoolPath}`);
          this.snackbarText = "no machineIdentifier - please define one";
          this.snackbarIsVisible = true;
          this.isRefreshing = false;
        } //if((machineIdentifier && this.useMachineIdentifierTag) || (!machineIdentifier && !this.useMachineIdentifierTag))
        else {
          let diagnosticSettings = await azureVirtualdesktop.GetDignosticSettings(
            hostPoolPath
          );
          let workspacePath =
            diagnosticSettings?.value[0]?.properties?.workspaceId;
          if (workspacePath) {
            log.Info(`LoggingWorkspace: ${workspacePath}`);
            let workspace = await azureVirtualdesktop.GetLogAnalyticsWorkspace(
              workspacePath
            );
            log.Info(
              `LoganalyticsWorkspaceID: ${workspace.properties.customerId}`
            );

            let activeSessionLogs = await azloganalytics.GetActiveSessionsForPeriod(
              workspace.properties.customerId,
              machineIdentifier,
              this.daysSelect
            );

            let maxUserCountLogs = await azloganalytics.GetMaxUsersForHostpool(
              workspace.properties.customerId,
              machineIdentifier,
              30
            );

            let cpuUsageLogs = await azloganalytics.GetCpuUsageForPeriod(
              workspace.properties.customerId,
              machineIdentifier,
              this.daysSelect
            );

            let memoryUsageLogs = await azloganalytics.GetMemoryUsageForPeriod(
              workspace.properties.customerId,
              machineIdentifier,
              this.daysSelect
            );

            this.maxUserSeries = await dtHelper.tranformToApexBarData(
              maxUserCountLogs
            );

            this.activeSessionsSeries = await dtHelper.transformToApexTimelineFormat(
              activeSessionLogs
            );

            this.cpuUsageSeries = await dtHelper.transformToApexTimelineFormat(
              cpuUsageLogs
            );

            this.memoryUsageSeries = await dtHelper.transformToApexTimelineFormat(
              memoryUsageLogs
            );
          }
          else
          {            
            log.Error(`no workspaceid for ${hostPoolPath}`);
            this.snackbarText = "no workspaceid - please add loganalytics workspace";
            this.snackbarIsVisible = true;
          }

          this.isRefreshing = false;
        }
      }
    },
  },
};
</script>
<style>
.fab-container {
  position: fixed;
  bottom: 0;
  right: 0;
}
</style>
