<template>
  <div>
    <v-container class="pa-5" fluid>
      <v-row>
        <v-col xs="12" sm="12" md="5" cols="12">
          <HostPoolsCombobox
            @update="selectedHostPools = $event"
            :disabled="userErrorsLoading || userConnectionsLoading"
            multiple
          />
        </v-col>

        <v-col xs="12" sm="12" md="2" cols="12">
          <v-menu
            v-model="dateFromMenu"
            :close-on-content-click="false"
            max-width="290"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="formatDateChooser(dateFrom)"
                clearable
                outlined
                filled
                label="choose start date"
                readonly
                hide-details
                dark
                v-bind="attrs"
                v-on="on"
                @click:clear="dateFrom = null"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dateFrom"
              @change="
                approveDate();
                dateFromMenu = false;
              "
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col xs="12" sm="12" md="2" cols="12">
          <v-menu
            v-model="dateToMenu"
            :close-on-content-click="false"
            max-width="290"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="formatDateChooser(dateTo)"
                clearable
                outlined
                filled
                label="choose end date"
                readonly
                hide-details
                dark
                v-bind="attrs"
                v-on="on"
                @click:clear="dateTo = null"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dateTo"
              @change="
                approveDate();
                dateToMenu = false;
              "
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col xs="12" sm="12" md="2" cols="12">
          <v-btn
            large
            color="green"
            dark
            @click="fetchLogs"
            :loading="userErrorsLoading || userConnectionsLoading"
            :disabled="
              userErrorsLoading ||
                userConnectionsLoading ||
                !(dateFrom || dateTo) ||
                selectedHostPools.length === 0
            "
            class="elevation-2"
          >
            fetch Logs
          </v-btn>
          <v-btn
            large
            color="orange"
            dark
            class="elevation-2"
            v-if="userErrorsLoading || userConnectionsLoading"
            @click="cancelationRequested = true"
          >
            Stop
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col xs="12" sm="12" md="2" col="12">
          <v-combobox
            :items="logTypes"
            v-model="selectedLogTypes"
            solo
            hide-details
          >
          </v-combobox>
        </v-col>
      </v-row>

      <!-- 
        ***************************
        * User Error Logs 
        ***************************
      -->
      <div v-if="selectedLogTypes == 'Error Logs'" class="my-2">
        <v-row>
          <v-col xs="12" sm="12" md="2" col="12">
            <v-card class="mx-auto" height="200">
              <v-card-title class="text-h6">
                Users
              </v-card-title>
              <v-card-text>
                <v-row align="center">
                  <v-col class="text-h2" cols="12">
                    {{ uniqueErrorLogUser() }}
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col xs="12" sm="12" md="2" col="12">
            <v-card class="mx-auto" height="200">
              <v-card-title class="text-h6">
                Unique Errors
              </v-card-title>
              <v-card-text>
                <v-row align="center">
                  <v-col class="text-h2" cols="12">
                    {{ uniqueErrors() }}
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col xs="12" sm="12" md="6" col="12">
            <apexchart
              ref="ErrorCountChart"
              type="bar"
              height="200"
              :options="chartOptions"
              :series="getCountOfErrorsSeries()"
            ></apexchart>
          </v-col>
        </v-row>
        <v-data-table
          v-if="userErrors"
          :headers="errorLogheaders"
          :items="userErrors"
          :loading="userErrorsLoading"
          :search="errorSearchBarText"
          class="mt-5 elevation-2"
          ref="userErrorsTable"
        >
          <template v-slot:top>
            <v-text-field
              v-model="errorSearchBarText"
              class="mx-4"
              prepend-inner-icon="mdi-magnify"
            />
          </template>
          <template v-slot:[`item._ResourceId`]="{ item }">
            {{ getHostpoolFromResourceId(item._ResourceId) }}
          </template>
        </v-data-table>
      </div>

      <!-- 
        ***************************
        * User Connection Logs 
        ***************************
      -->
      <div v-if="selectedLogTypes == 'Connection Logs'" class="my-2">
        <v-row>
          <v-col xs="12" sm="12" md="2" col="12">
            <v-card class="mx-auto">
              <v-card-title class="text-h6">
                Users
              </v-card-title>
              <v-card-text>
                <v-row align="center">
                  <v-col class="text-h2" cols="12">
                    {{ uniqueConnectionLogUser() }}
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col xs="12" sm="12" md="2" col="12">
            <v-card class="mx-auto">
              <v-card-title class="text-h6">
                Operating Systems
              </v-card-title>
              <v-card-text>
                <v-row align="center">
                  <v-col class="text-h2" cols="12">
                    {{ uniqueConnectionLogOS() }}
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col xs="12" sm="12" md="2" col="12">
            <v-card class="mx-auto">
              <v-card-title class="text-h6">
                Startet Sessions
              </v-card-title>
              <v-card-text>
                <v-row align="center">
                  <v-col class="text-h2" cols="12">
                    {{ startedSessions() }}
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <!--
          <v-col xs="12" sm="12" md="2" col="12">
            <v-card class="mx-auto">
              <v-card-title class="text-h6">
                Unique Errors
              </v-card-title>
              <v-card-text>
                <v-row align="center">
                  <v-col class="text-h2" cols="12">
                    {{ uniqueErrors() }}
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          -->
        </v-row>
        <v-data-table
          v-if="userConnections"
          :headers="connectionLogheaders"
          :items="userConnections"
          :loading="userConnectionsLoading"
          :search="connectionsSearchBarText"
          class="mt-5 elevation-2"
          ref="userConnectionsTable"
        >
          <template v-slot:top>
            <v-text-field
              v-model="connectionsSearchBarText"
              class="mx-4"
              prepend-inner-icon="mdi-magnify"
            />
          </template>

          <template v-slot:[`item._ResourceId`]="{ item }">
            {{ getHostpoolFromResourceId(item._ResourceId) }}
          </template>
        </v-data-table>
      </div>
    </v-container>
  </div>
</template>

<script>
import HostPoolsCombobox from "./HostPoolsCombobox.vue";
//import azloganalytics from "../Services/azure-loganalytics"
import log from "../Services/log";
import azureLoganalytics from "../Services/azure-loganalytics";
import moment from "moment";

export default {
  name: "VdiDevices",
  components: {
    HostPoolsCombobox,
  },
  data: () => ({
    selectedCodeSymbolic: "",
    errorSearchBarText: "",
    connectionsSearchBarText: "",
    selectedHostPools: [],
    errorsTemp: [],
    userErrors: [],
    userConnections: [],
    //selectedUserErrors: [],
    cancelationRequested: false,
    //expanded: [],
    dateFrom: null,
    dateTo: null,
    dateFromMenu: false,
    dateToMenu: false,
    logTypes: ["Connection Logs", "Error Logs"],
    selectedLogTypes: ["Connection Logs"],

    errorLogheaders: [
      //{ text: "TenantId", value: "TenantId" },
      { text: "TimeGenerated", value: "TimeGenerated" },
      //{ text: "CorrelationId", value: "CorrelationId" },
      { text: "HostPool", value: "_ResourceId" },
      { text: "UserName", value: "UserName" },
      { text: "ActivityType", value: "ActivityType" },
      { text: "Source", value: "Source" },
      //{ text: "Code", value: "Code" },
      { text: "CodeSymbolic", value: "CodeSymbolic" },
      { text: "Message", value: "Message" },
      { text: "ServiceError", value: "ServiceError" },
      { text: "Operation", value: "Operation" },
      { text: "SourceSystem", value: "SourceSystem" },
      { text: "Type", value: "Type" },
      //{ text: "ResourceId", value: "_ResourceId" },
    ],

    connectionLogheaders: [
      { text: "TimeGenerated", value: "TimeGenerated" },
      { text: "HostPool", value: "_ResourceId" },
      { text: "UserName", value: "UserName" },
      { text: "State", value: "State" },
      { text: "ClientOS", value: "ClientOS" },
      { text: "ClientVersion", value: "ClientVersion" },
      { text: "ClientType", value: "ClientType" },
      { text: "ClientIPAddress", value: "ClientIPAddress" },
      { text: "ConnectionType", value: "ConnectionType" },
      { text: "SessionHostName", value: "SessionHostName" },
      { text: "SessionHostIPAddress", value: "SessionHostIPAddress" },
      { text: "SessionHostOSVersion", value: "SessionHostOSVersion" },
      { text: "SessionHostOSDescription", value: "SessionHostOSDescription" },
      { text: "GatewayRegion", value: "GatewayRegion" },
    ],

    userErrorsLoading: false,
    userConnectionsLoading: false,
  }),

  computed: {
    chartOptions: function() {
      return {
        chart: {
          height: 200,
          type: "bar",
          background: "#fff",
          events: {
            click: (chart, w, e) => {
              let clickedCodeSymbolic =
                e.config.series[0].data[e.dataPointIndex].x;
              this.selectedCodeSymbolic =
                this.selectedCodeSymbolic == clickedCodeSymbolic
                  ? ""
                  : clickedCodeSymbolic;
              this.filterErrorsByChart();
            },
          },
        },

        plotOptions: {
          bar: {
            columnWidth: "90%",

            distributed: true,
            horizontal: false,
            dataLabels: {
              position: "top",
            },
          },
        },
        dataLabels: {
          enabled: true,
        },
        legend: {
          show: false,
        },

        yaxis: {
          labels: {
            show: true,
          },
        },
        xaxis: {
          type: "category",
          labels: {
            show: false,
          },
        },
      };
    },
  },

  methods: {
    filterErrorsByChart() {
      if (this.selectedCodeSymbolic) {
        log.Info(`Filtering by barchart: ${this.selectedCodeSymbolic}`);
        this.errorsTemp = JSON.parse(JSON.stringify(this.userErrors));
        this.userErrors = this.userErrors.filter(
          (row) => row.CodeSymbolic == this.selectedCodeSymbolic
        );
      } else {
        log.Info("clearing barchartfilter");
        this.userErrors = JSON.parse(JSON.stringify(this.errorsTemp));
      }
    },

    approveDate() {
      if (this.dateTo < this.dateFrom) {
        this.dateTo = this.dateFrom;
      }
      let to = this.dateTo ? moment(this.dateTo) : null;
      let from = this.dateFrom ? moment(this.dateFrom) : null;
      if (to && from) {
        let days = to.diff(from, "days");
        if (days > 30) {
          this.dateTo = from.add(30, "days").format("YYYY-MM-DD");
        }
      }
    },

    uniqueErrorLogUser() {
      try {
        let items = this.$refs.userErrorsTable.$children[0].filteredItems;
        let numOfUniqueUsers = items
          .map((item) => item.UserName)
          .filter((value, index, self) => self.indexOf(value) === index).length;

        return numOfUniqueUsers;
      } catch {
        return 0;
      }
    },

    uniqueConnectionLogUser() {
      try {
        let items = this.$refs.userConnectionsTable.$children[0].filteredItems;
        let numOfUniqueUsers = items
          .map((item) => item.UserName)
          .filter((value, index, self) => self.indexOf(value) === index).length;

        return numOfUniqueUsers;
      } catch {
        return 0;
      }
    },
    uniqueConnectionLogOS() {
      try {
        let items = this.$refs.userConnectionsTable.$children[0].filteredItems;
        let numOfUniqueOS = items
          .map((item) => item.ClientOS)
          .filter((value, index, self) => self.indexOf(value) === index).length;

        return numOfUniqueOS;
      } catch {
        return 0;
      }
    },

    startedSessions() {
      try {
        let items = this.$refs.userConnectionsTable.$children[0].filteredItems;
        let numOfStartedSessions = items
          .map((item) => item.State)
          .filter((value) => value === "Started").length;

        return numOfStartedSessions;
      } catch {
        return 0;
      }
    },

    uniqueErrors() {
      try {
        let items = this.$refs.userErrorsTable.$children[0].filteredItems;
        let numOfUniqueUsers = items
          .map((item) => item.Code)
          .filter((value, index, self) => self.indexOf(value) === index).length;

        return numOfUniqueUsers;
      } catch {
        return 0;
      }
    },

    getCountOfErrorsSeries() {
      let series = [];
      try {
        let items = this.$refs.userErrorsTable.$children[0].filteredItems;
        if (items) {
          let allSymbolics = items.map((item) => item.CodeSymbolic);
          let distinctSymbolics = allSymbolics.filter(
            (value, index, self) => self.indexOf(value) === index
          );
          let seriesEntry = {};

          seriesEntry.data = distinctSymbolics.map((row) => ({
            x: row,
            y: allSymbolics.filter((x) => x == row).length,
          }));
          series.push(seriesEntry);
        }
        return series;
      } catch {
        return series;
      }
    },

    async fetchLogs() {
      this.selectedCodeSymbolic = "";
      await this.getUserErrors();
      await this.getUserConnections();
      this.cancelationRequested = false;
    },

    async getUserConnections() {
      this.userConnectionsLoading = true;
      this.userConnections = [];
      if (this.dateFrom != null || this.dateTo != null) {
        for (var hostPool of this.selectedHostPools) {
          if (this.cancelationRequested) break;
          let connectionLogs = await azureLoganalytics.GetAllConnectionLogsForHostPool(
            hostPool.id,
            this.dateFrom,
            this.dateTo
          );
          connectionLogs.forEach((item) => {
            item.TimeGenerated = this.formatDate(item.TimeGenerated);
          });
          this.userConnections.push(...connectionLogs);
        }
      }
      this.userConnectionsLoading = false;
    },

    async getUserErrors() {
      this.userErrorsLoading = true;
      this.userErrors = [];
      if (this.dateFrom != null || this.dateTo != null) {
        for (var hostPool of this.selectedHostPools) {
          if (this.cancelationRequested) break;
          let errors = await azureLoganalytics.GetAllErrorsForHostPool(
            hostPool.id,
            this.dateFrom,
            this.dateTo
          );
          errors.forEach((item) => {
            item.TimeGenerated = this.formatDate(item.TimeGenerated);
          });
          this.userErrors.push(...errors);
        }
      }
      this.userErrorsLoading = false;
      this.cancelationRequested = false;
    },
    getHostpoolFromResourceId(value) {
      return value.substring(value.lastIndexOf("/") + 1, value.length);
    },
  },

  async created() {
    log.Info("Module: UserErrors create ended");
  },
};
</script>
<style>
#app {
  background-color: var(--v-primary-darken1) !important;
}
</style>
