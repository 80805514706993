import moment from "moment";
export default {
  Action(message) {
    console.log(
      `%c${Now()} -- ACTION -- ${message}`,
      "color:DeepSkyblue; font-family:monospace; font-size: 12px"
    );
  },
  Info(message) {
    console.log(
      `%c${Now()} -- INFO -- ${message}`,
      "color:Gold; font-family:monospace; font-size: 12px"
    );
  },

  WriteObjectInfo(object) {
    this.Info(JSON.stringify(object, null, 2));
  },
  Error(message, detail) {
    console.group(
      `%c${Now()} -- ERROR -- ${message}`,
      "color:red; font-family:monospace; font-size: 12px"
    );
    if(detail?.url) {
      console.log(`url: ${detail?.url}`)
      console.log(`code: ${detail?.code}`)
    }
    if(detail){
      console.log(detail);
    }
    console.groupEnd();
  },
  Result(message) {
    console.log(
      `%c${Now()} -- RESULT -- ${message}`,
      "color:Lawngreen; font-family:monospace; font-size: 12px"
    );
  },
  TitleBar(title) {
    console.log(
      `%c${title}`,
      "font-weight: bold; font-size:40px;color:white;" +
        OutlineText("#000000", 2, 5)
      //text-shadow: 3px 3px 0 rgb(217,31,38) , 6px 6px 0 rgb(226,91,14) , 9px 9px 0 rgb(245,221,8) , 12px 12px 0 rgb(5,148,68) , 15px 15px 0 rgb(2,135,206) , 18px 18px 0 rgb(4,77,145) , 21px 21px 0 rgb(42,21,113);
    );
  },
};

// private function
function Now() {
  let cd = new Date();
  return moment(cd).format("DD.MM.YYYY HH:mm:ss");
  //return `${cd.getDate()}.${cd.getMonth()}.${cd.getFullYear()} ${cd.getHours()}:${cd.getMinutes()}:${cd.getSeconds()}`;
}

// returns css - text-shadow

function OutlineText(color, thickness, shadowWith) {
  let retVal = "text-shadow: ";
  retVal += `${-thickness}px ${-thickness}px 0 ${color},`;
  retVal += ` 0px ${-thickness}px 0 ${color},`;
  retVal += ` ${thickness}px ${-thickness}px 0 ${color},`;
  retVal += ` ${thickness}px  0px 0 ${color},`;

  retVal += ` ${thickness}px  ${thickness}px 0 ${color},`;
  retVal += ` 0px  ${thickness}px 0 ${color},`;
  retVal += `${-thickness}px  ${thickness}px 0 ${color},`;
  for (var i = 1; i < shadowWith; i++) {
    retVal += `${i}px ${i}px 0 ${color},`;
  }
  retVal += `${shadowWith}px ${shadowWith}px 0 ${color};`;
  return retVal;
}
