import * as msal from "@azure/msal-browser";
import log from "./log";
// MSAL object used for signing in users with MS identity platform
let msalApp;

export default {
  async configure(config) {
    if (msalApp) {
      return;
    }

    if (!config.auth.clientId) {
      return;
    }

    // Create PublicClientApp
    log.Action("Creating PublicClientApp");
    msalApp = new msal.PublicClientApplication(config);

    /*
    msalApp
      .handleRedirectPromise()
      .then((tokenResponse) => {
        console.log("Tokenresponse: " + tokenResponse);
      })
      .catch((error) => {
        console.error(error);
      });
      */
  },

  //
  // Return the configured client id
  //
  clientId() {
    return msalApp ? msalApp.clientId : null;
  },

  //
  // Login a user with a popup
  //
  async login(scopes = ["user.read", "openid", "profile", "email"]) {
    if (!msalApp) {
      return;
    }

    //const LOGIN_SCOPES = ['user.read', 'openid', 'profile', 'email']
    await msalApp.loginPopup({
      scopes,
      //prompt: "select_account",
    });
  },

  //
  // Logout any stored user
  //
  logout() {
    if (!msalApp) {
      return;
    }

    msalApp.logoutPopup();
  },

  //
  // Call to get user, probably cached and stored locally by MSAL
  //
  user() {
    if (!msalApp) {
      return null;
    }

    const currentAccounts = msalApp.getAllAccounts();
    if (!currentAccounts || currentAccounts.length === 0) {
      // No user signed in
      return null;
    } else {
      return currentAccounts[0];
    }
  },

  //
  // Call through to acquireTokenSilent or acquireTokenPopup
  //
  async acquireToken(scopes = ["user.read"]) {
    if (!msalApp) {
      return null;
    }

    // Set scopes for token request
    const accessTokenRequest = {
      scopes,
      account: this.user(),
    };

    let tokenResp;
    try {
      // 1. Try to acquire token silently
      log.Action("MSAL aquire token silent");
      tokenResp = await msalApp.acquireTokenSilent(accessTokenRequest);
      log.Result("MSAL acquireTokenSilent was successful");
    } catch (err) {
      // 2. Silent process might have failed so try via popup
      log.Action("MSAL aquire token popup");
      tokenResp = await msalApp.acquireTokenPopup(accessTokenRequest);
      log.Result("MSAL acquireTokenPopup was successful");
    }

    // Just in case check, probably never triggers
    if (!tokenResp.accessToken) {
      throw new Error("### accessToken not found in response, that's bad");
    }

    return tokenResp.accessToken;
  },

  //
  // Clear any stored/cached user
  //
  clearLocal() {
    if (msalApp) {
      for (let entry of Object.entries(localStorage)) {
        let key = entry[0];
        if (key.includes("login.windows")) {
          localStorage.removeItem(key);
        }
      }
    }
  },

  //
  // Check if we have been setup & configured
  //
  isConfigured() {
    return msalApp != null;
  },
};
