<template>
  <div>
    <v-container class="pa-5" fluid>
      <v-row>
        <v-col xs="12" sm="12" md="6" cols="12">
          <HostPoolsCombobox
            @update="selectedHostPools = $event"
            :selectionModel="selectedHostPools"
            :disabled="sessionHostsLoading"
            multiple
          />
        </v-col>
        <v-col xs="12" sm="12" md="6" col="12">
          <v-btn
            large
            color="green"
            dark
            @click="getSessionHosts"
            :loading="sessionHostsLoading"
            :disabled="sessionHostsLoading"
            class="elevation-2"
          >
            Refresh Devicedata
          </v-btn>
          <v-btn
            large
            color="orange"
            dark
            class="elevation-2"
            v-if="sessionHostsLoading"
            @click="cancelationRequested = true"
          >
            Stop
          </v-btn>
        </v-col>
      </v-row>
      <v-data-table
        v-if="sessionHosts"
        :headers="headers"
        :items="sessionHosts"
        :loading="sessionHostsLoading"
        :search="searchBarText"
        single-expand
        show-group-by
        class="mt-5 elevation-2"
        @click:row="(item, slot) => logExpand(item, slot)"
      >
        <template v-slot:top>
          <v-text-field
            v-model="searchBarText"
            class="mx-4"
            prepend-inner-icon="mdi-magnify"
          />
          <v-dialog v-model="dialogRestartVM" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Restart this virtual machine?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="dialogRestartVM = false"
                  >Cancel</v-btn
                >
                <v-btn color="blue darken-1" text @click="restartVMConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDeallocateVM" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Deallocate this virtual machine?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="dialogDeallocateVM = false"
                  >Cancel</v-btn
                >
                <v-btn color="blue darken-1" text @click="deallocateVMConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :loading="item.isRefreshing"
                color="green"
                outlined
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon @click.stop="refreshDeviceEntry(item)">
                  mdi-refresh
                </v-icon>
              </v-btn>
            </template>
            <span>Refresh</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :loading="item.isStarting"
                :disabled="item.properties.status == 'Available'"
                class="mx-2"
                color="green"
                outlined
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon @click.stop="doStartVM(item)">
                  mdi-play
                </v-icon>
              </v-btn>
            </template>
            <span>Start</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :loading="item.isDeallocating"
                :disabled="item.properties.status != 'Available'"
                class="mx-2"
                color="red"
                outlined
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon @click.stop="showDeallocateVMDialog(item)">
                  mdi-stop
                </v-icon>
              </v-btn>
            </template>
            <span>Stop</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :loading="item.isRestarting"
                :disabled="item.properties.status != 'Available'"
                class="mx-2"
                color="orange"
                outlined
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon @click.stop="showRestartVMDialog(item)">
                  mdi-restart
                </v-icon>
              </v-btn>
            </template>
            <span>Restart</span>
          </v-tooltip>
        </template>

        <template v-slot:[`item.properties.status`]="{ item }">
          <v-chip :color="getStatusColor(item.properties.status)" dark>
            {{ item.properties.status }}
          </v-chip>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-container class="pa-0" fluid>
              <v-row no-gutters>
                <v-col xs="12" sm="12" md="7" lg="7" cols="12">
                  <div class="py-2">
                    <v-card class="mx-auto" color="#ffffff">
                      <v-simple-table class="grey lighten-3" v-if="item.vmData">
                        <tbody>
                          <tr>
                            <td>Machine Status</td>
                            <td class="font-weight-medium">
                              <v-chip
                                dark
                                :color="
                                  getStatusLevelColor(
                                    item.vmInstanceView.statuses[1].level
                                  )
                                "
                              >
                                {{ item.vmInstanceView.statuses[1].code }}
                              </v-chip>
                            </td>
                          </tr>
                          <tr>
                            <td>OS Name</td>
                            <td class="font-weight-medium">
                              {{
                                !!item.vmInstanceView.osName
                                  ? item.vmInstanceView.osName +
                                    "(" +
                                    item.vmInstanceView.osVersion +
                                    ")"
                                  : ""
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td>Agent Version</td>
                            <td class="font-weight-medium">
                              {{
                                !!item.vmInstanceView.vmAgent
                                  ? item.vmInstanceView.vmAgent.vmAgentVersion
                                  : ""
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td>Last Modified by</td>
                            <td class="font-weight-medium">
                              {{ item.systemData.lastModifiedBy }}
                            </td>
                          </tr>
                          <tr>
                            <td>Last Modified at</td>
                            <td class="font-weight-medium">
                              {{ item.systemData.lastModifiedAt }}
                            </td>
                          </tr>
                          <tr>
                            <td>Virtual Machine Location</td>
                            <td class="font-weight-medium">
                              {{ item.vmData.location }}
                            </td>
                          </tr>
                          <tr>
                            <td>Virtual Machine Size</td>
                            <td class="font-weight-medium">
                              {{
                                item.vmData.properties.hardwareProfile.vmSize
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td>License Type</td>
                            <td class="font-weight-medium">
                              {{ item.vmData.properties.licenseType }}
                            </td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                    </v-card>
                  </div>
                </v-col>
                <v-col xs="12" sm="12" md="5" lg="5" cols="12">
                  <div class="py-2 pl-2">
                    <v-card class="mx-auto" color="#ffffff">
                      <v-simple-table class="grey lighten-3">
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left font-weight-black">
                                HealthCheckName
                              </th>
                              <th class="text-left font-weight-black">
                                HealthCheckResult
                              </th>
                              <th class="text-left font-weight-black">
                                ErrorCode
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(entry, i) in item.properties
                                .sessionHostHealthCheckResults"
                              :key="i"
                            >
                              <td>{{ entry.healthCheckName }}</td>
                              <td>{{ entry.healthCheckResult }}</td>
                              <td>
                                {{ entry.additionalFailureDetails.errorCode }}
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-card>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </td>
        </template>
        <template v-slot:[`item.expandicon`]="">
          <v-icon large> mdi-chevron-right </v-icon>
        </template>
        <!--
        <template v-slot:[`item.properties.lastHeartBeat`]="{ item }">
          {{ item.properties.lastHeartBeat | formatDate }}
        </template>
        -->
      </v-data-table>
    </v-container>
    <v-snackbar
      v-model="snackbarIsVisible"
      multi-line
      :timeout="snackbarTimeOut"
    >
      <h4 :class="getPurposeColor(snackbarPurpose)">{{snackbarPurpose}}</h4>
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="snackbarIsVisible = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import HostPoolsCombobox from "./HostPoolsCombobox.vue";
import azvirtualdesktop from "../Services/azure-virtualdesktop";
import log from "../Services/log";
export default {
  name: "VdiDevices",
  components: {
    HostPoolsCombobox,
  },
  data: () => ({
    searchBarText: "",
    sessionHosts: [],
    selectedHostPools: [],
    cancelationRequested: false,
    dialogRestartVM: false,
    dialogDeallocateVM: false,
    actualRestartItem: Object(),
    actualDeallocateItem: Object(),
    actualStartItem: Object(),

    headers: [
      { text: "", value: "expandicon", width: 20, groupable: false },
      { text: "HostPool", value: "hostPool" },
      { text: "Name", value: "name" },
      { text: "OsVersion", value: "properties.osVersion" },
      { text: "LastHeartbeat", value: "properties.lastHeartBeat" },
      { text: "Sessions", value: "properties.sessions" },
      { text: "MaxSessionLimit", value: "maxSessionLimit" },
      { text: "DrainMode", value: "drainMode" },
      { text: "LoadBalancerType", value: "loadBalancerType" },
      { text: "Status", value: "properties.status" },
      { text: "Actions", value: "actions", sortable: false, groupable: false },
      //{ text: "HealthErrors", value: "healthErrors" },
    ],
    sessionHostsLoading: false,
    snackbarIsVisible: false,
    snackbarText: "",
    snackbarTimeOut: 3000,
    snackbarPurpose: "Error",
  }),

  methods: {
    getColor(healthErrors) {
      if (healthErrors > 2) return "red";
      else if (healthErrors > 0) return "orange";
      else return "green";
    },
    getStatusColor(status) {
      if (status == "Available") return "green";
      else return "orange";
    },
    getStatusLevelColor(statusLevel) {
      if (statusLevel == "Error") return "red";
      else if (statusLevel == "Warning") return "orange";
      else return "green";
    },

    getPurposeColor(purpose){ 
      let retVal;
      switch(purpose){
        case "Result":
          retVal = "green--text text--lighten-1"
          break;
        case "Error":
          retVal = "red--text text--lighten-1"
          break;
        default:
          break;
      }
      return retVal;
    },

    showSnackbar(purpose,message)
    {
      this.snackbarPurpose = purpose
      this.snackbarText = message
      this.snackbarIsVisible = true
    },

    ///////////////////////////
    // Refresh Device entry
    async refreshDeviceEntry(item) {
      item.isRefreshing = true;
      let itemIndex = this.sessionHosts.indexOf(item);
      //console.log(item)
      //////to be changed for device
      azvirtualdesktop
        .getSessionHostById(item.id)
        .then((refreshedItem) => {
          refreshedItem.drainMode = refreshedItem.properties.allowNewSession
            ? "off"
            : "on";
          refreshedItem.hostPool = item.hostPool;
          refreshedItem.maxSessionLimit = item.maxSessionLimit;
          refreshedItem.loadBalancerType = item.loadBalancerType;
          refreshedItem.properties.lastHeartBeat = this.formatDate(
            refreshedItem.properties.lastHeartBeat
          );
          refreshedItem.systemData.lastModifiedAt = refreshedItem.systemData
            .lastModifiedAt
            ? this.formatDate(refreshedItem.systemData.lastModifiedAt)
            : "";
          // Create healthErrorcolumn from healthCheckArray
          refreshedItem.healthErrors = refreshedItem.properties.sessionHostHealthCheckResults.reduce(
            (accum, item) => {
              return (
                accum + (item.additionalFailureDetails.errorCode > 0 ? 1 : 0)
              );
            },
            0
          );
          refreshedItem.isRefreshing = false;
          //console.log(refreshedItem);
          Object.assign(this.sessionHosts[itemIndex], refreshedItem);
        })
        .catch((error) => {
          log.Error("refresh failed", error);
        });
    },

    // Start VM

    async doStartVM(item) {
      try {        
        await azvirtualdesktop.startVM(item.properties.resourceId);
        let resIdSplits = item.properties.resourceId.split("/")
        this.showSnackbar("Result", `start job created for: ${resIdSplits[resIdSplits.length-1]}`)
      } catch (error) {
        log.Error("starting vm failed", error);
        if (error.code === 403) {
          this.showSnackbar("Error","start vm failed - you have no permission")
        } else {
          this.showSnackbar("Error",`start vm failed code: ${error.code}`)
        }
      }
    },

    /// Stop VM
    async showDeallocateVMDialog(item) {
      if (item.properties.status == "Available") {
        this.dialogDeallocateVM = true;
        this.actualDeallocateItem = item;
      }
    },
    async deallocateVMConfirm() {
      this.actualDeallocateItem.isDeallocating = true;
      let itemIndex = this.sessionHosts.indexOf(this.actualDeallocateItem);
      //log.Info(`index is: '${itemIndex}'`);
      try {
        //console.log(this.actualDeallocateItem.properties.resourceId);
        await azvirtualdesktop.deallocateVM(
          this.actualDeallocateItem.properties.resourceId
        );
        let resIdSplits = this.actualDeallocateItem.properties.resourceId.split("/")
        this.showSnackbar("Result", `job created for deallocating: ${resIdSplits[resIdSplits.length-1]}`)
        this.actualDeallocateItem.isDeallocating = false;
        Object.assign(this.sessionHosts[itemIndex], this.actualDeallocateItem);
      } catch (error) {
        log.Error("deallocating vm failed", error);
        if (error.code === 403) {
          this.showSnackbar("Error", "deallocating vm failed - you have no permission");
        } else {
          this.showSnackbar("Error",`deallocating vm failed code: ${error.code}`);
        }
        
      }
      this.dialogDeallocateVM = false;
      this.actualDeallocateItem.isDeallocating = false;
      //await this.refreshUserEntry(this.actualLogoutItem);
      this.actualDeallocateItem = null;
    },

    ////////////////////////
    // Show restart dialog
    async showRestartVMDialog(item) {
      if (item.properties.status == "Available") {
        this.dialogRestartVM = true;
        this.actualRestartItem = item;
      }
    },
    ///////////////////////
    // do the restart
    async restartVMConfirm() {
      this.actualRestartItem.isRestarting = true;
      let itemIndex = this.sessionHosts.indexOf(this.actualRestartItem);
      log.Info(`index is: '${itemIndex}'`);
      try {
        //console.log(this.actualRestartItem.properties.resourceId);
        await azvirtualdesktop.restartVM(
          this.actualRestartItem.properties.resourceId
        );
        let resIdSplits = this.actualRestartItem.properties.resourceId.split("/")
        this.showSnackbar("Result", `restart job created for: ${resIdSplits[resIdSplits.length-1]}`)
        this.actualRestartItem.isRestarting = false;
        Object.assign(this.sessionHosts[itemIndex], this.actualRestartItem);        
      } catch (error) {
        log.Error("restarting vm failed", error);
        if (error.code === 403) {
          this.showSnackbar("Error","restarting vm failed - you have no permission");
        } else {
          this.showSnackbar("Error",`restarting vm failed code: ${error.code}`);
        }        
      }
      this.dialogRestartVM = false;
      this.actualRestartItem.isRestarting = false;
      //await this.refreshUserEntry(this.actualLogoutItem);
      this.actualRestartItem = null;
    },
    
    async getSessionHosts() {
      this.sessionHostsLoading = true;
      this.sessionHosts = [];
      for (var hostPool of this.selectedHostPools) {
        if (this.cancelationRequested) break;
        let hosts = await azvirtualdesktop.getSessionHostsWithPath(hostPool.id); //.value;
        hosts.forEach((item) => {
          item.drainMode = item.properties.allowNewSession ? "off" : "on";
          item.hostPool = hostPool.name;
          item.maxSessionLimit = hostPool.properties.maxSessionLimit;
          item.loadBalancerType = hostPool.properties.loadBalancerType;
          item.properties.lastHeartBeat = this.formatDate(
            item.properties.lastHeartBeat
          );
          item.systemData.lastModifiedAt = item.systemData.lastModifiedAt
            ? this.formatDate(item.systemData.lastModifiedAt)
            : "";
          // Create healthErrorcolumn from healthCheckArray
          item.healthErrors = item.properties.sessionHostHealthCheckResults.reduce(
            (accum, item) => {
              return (
                accum + (item.additionalFailureDetails.errorCode > 0 ? 1 : 0)
              );
            },
            0
          );
        });
        this.sessionHosts.push(...hosts);
      }

      this.sessionHostsLoading = false;
      this.cancelationRequested = false;
    },
    async logExpand(item, slot) {
      if (!slot.isExpanded) {
        log.Info("loading vmData");
        await azvirtualdesktop
          .getSessionHostVMData(item.properties.resourceId)
          .then((result) => {
            item.vmData = result;
          })
          .catch((error) => {
            log.Error("Error expanding user", error);
          });
        log.Info("loading vmInstanceView");
        await azvirtualdesktop
          .getSessionHostInstanceView(item.properties.resourceId)
          .then((result) => {
            //console.log(result);
            item.vmInstanceView = result;
          })
          .catch((error) => {
            log.Error("Error expanding user", error);
          });
      }
      slot.expand(!slot.isExpanded);
      //item.isExpanded = !item.isExpanded;
    },
  },

  async created() {
    log.Info("Create ended");
  },
};
</script>
<style>
#app {
  background-color: var(--v-primary-darken1) !important;
}
</style>
