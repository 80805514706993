import Vue from "vue";
import Vuex from "vuex";
import hostpoolservice from "./modules/hostpoolservice";
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    //hostpoolsync,
    hostpoolservice,
  },
});
