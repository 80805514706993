<template>
  <v-autocomplete
    :items="hostPools"
    item-text="name"
    item-value="id"
    :multiple="multiple"
    v-model="selectedHostPools"
    :small-chips="multiple"
    label="Select Hostpools"
    solo
    light
    hide-details
    prepend-inner-icon="mdi-server"
    :loading="hostPoolsLoading"
    loader-height="5"
    @change="emitChanges"
    :disabled="disabled || hostPoolsLoading"
    return-object
  >
    <template
      v-slot:selection="{ attrs, item, parent, selected }"
      v-if="multiple"
    >
      <v-chip
        v-if="item === Object(item)"
        v-bind="attrs"
        color="primary"
        :input-value="selected"
        label
        :disabled="disabled"
      >
        <span class="pr-2">
          {{ item.name }}
        </span>
        <v-icon smal @click="parent.selectItem(item)">
          $delete
        </v-icon>
      </v-chip>
    </template>
    <template v-slot:prepend-inner>
      <v-icon>
        mdi-server
      </v-icon>
      <v-btn
        height="auto"
        icon
        @click="fetchHostPools"
        :loading="hostPoolsLoading"
        :disabled="hostPoolsLoading"
      >
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
    </template>
  </v-autocomplete>
</template>

<script>
//import azvirtualdesktop from "../Services/azure-virtualdesktop";
//import log from "../Services/log";
import { mapState /*, mapActions*/ } from "vuex";

export default {
  name: "HostPoolsCombobox",
  data: () => ({
    selectedHostPools: null,
    //hostPools: [],
    //hostPoolsLoading: false,
  }),
  props: {
    disabled: Boolean,
    multiple: Boolean,
  },

  computed: {
    /*
    SelectedHostPools: {
      get() {
        return this.syncSelection
          ? this.globalSelectedHostPools
          : this.selectedHostPools;
      },
      set(value) {
        this.store.commit("hostpoolservice/setSelectedHostPools", value);
      },
    },
    */
    ...mapState({
      hostPools: (state) => state.hostpoolservice.hostPools,
      hostPoolsLoading: (state) => state.hostpoolservice.hostPoolsLoading,
    }),
  },

  methods: {
    async fetchHostPools() {
      await this.$store.dispatch("hostpoolservice/fetchHostPools");
    },
    emitChanges() {
      this.$emit("update", this.selectedHostPools);
    },
  },
};
</script>
