import auth from "./auth";
import log from "./log";

const AZURE_MANAGEMENT_BASE = "https://management.azure.com";
const AZURE_VDI_SCOPES = [
  "https://management.core.windows.net//user_impersonation",
];
//const API_VERSION = "api-version=2021-03-09-preview";

let accessToken;

export default {
  async getSubscriptions() {
    let resp = await callAzureManagement(
      "/subscriptions?api-version=2021-04-01"
    );
    if (resp) {
      let data = await resp.json();
      return data;
    }
  },
  async getResourceGroups(subscription) {
    let resp = await callAzureManagement(
      `/subscriptions/${subscription}/resourcegroups?api-version=2021-04-01`
    );
    if (resp) {
      let data = await resp.json();
      return data;
    }
  },

  async getHostPools(subscription, resourceGroup) {
    let resp = await callAzureManagement(
      `/subscriptions/${subscription}/resourcegroups/${resourceGroup}/providers/Microsoft.DesktopVirtualization/hostPools?api-version=2021-03-09-preview`
    );
    if (resp) {
      let data = await resp.json();
      return data;
    }
  },

  async getSessionsHosts(subscription, resourceGroup, hostPool) {
    let resp = await callAzureManagement(
      `/subscriptions/${subscription}/resourcegroups/${resourceGroup}/providers/Microsoft.DesktopVirtualization/hostPools/${hostPool}/sessionHosts?api-version=2021-03-09-preview`
    );
    if (resp) {
      let data = await resp.json();
      return data;
    }
  },
  async getUserSessions(subscription, resourceGroup, hostPool, sessionHost) {
    let resp = await callAzureManagement(
      `/subscriptions/${subscription}/resourcegroups/${resourceGroup}/providers/Microsoft.DesktopVirtualization/hostPools/${hostPool}/sessionHosts/${sessionHost}/userSession?api-version=2021-03-09-preview`
    );
    if (resp) {
      let data = await resp.json();
      return data;
    }
  },

  async getHostPoolsWithPath(resourceGroupPath) {
    let resp = await callAzureManagement(
      `${resourceGroupPath}/providers/Microsoft.DesktopVirtualization/hostPools?api-version=2021-03-09-preview`
    );
    if (resp) {
      let data = await resp.json();
      return data;
    }
  },

  async getSessionHostById(id) {
    let resp = await callAzureManagement(
      `${id}?api-version=2022-02-10-preview`
    );
    if (resp) {
      let data = await resp.json();
      return data;
    }
  },

  async getSessionHostsWithPath(hostPoolPath) {
    let resp = await callAzureManagement(
      `${hostPoolPath}/SessionHosts?api-version=2021-03-09-preview`
    );
    if (resp) {
      let data = await getAllPages(resp);
      return data;
    }
  },

  async getSessionHostVMData(resourceid) {
    let resp = await callAzureManagement(
      `${resourceid}?api-version=2021-07-01`
    );
    if (resp) {
      let data = await resp.json();
      return data;
    }
  },
  async getSessionHostInstanceView(resourceid) {
    let resp = await callAzureManagement(
      `${resourceid}/instanceView?api-version=2021-07-01`
    );
    if (resp) {
      let data = await resp.json();
      return data;
    }
  },

  async getUserSessionsWithPath(sessionHostPath) {
    let resp = await callAzureManagement(
      `${sessionHostPath}/userSessions?api-version=2021-03-09-preview`
    );
    if (resp) {
      let data = await resp.json();
      return data;
    }
  },

  async getUserSessionWithPath(userSessionPath) {
    let resp = await callAzureManagement(
      `${userSessionPath}?api-version=2021-03-09-preview`
    );
    if (resp) {
      let data = await resp.json();
      return data;
    }
  },

  async disconnectUserSession(userSessionPath) {
    let resp = await callAzureManagementFunction(
      `${userSessionPath}/disconnect?api-version=2021-03-09-preview`,
      "Post"
    );

    if (resp) {
      //let data = await resp.json();
      //return data;
    }
  },
  async removeUserSession(userSessionPath) {
    let resp = await callAzureManagementFunction(
      `${userSessionPath}?api-version=2021-03-09-preview&force=True`,
      "Delete"
    );

    if (resp) {
      //let data = await resp.json();
      //return data;
    }
  },

  async restartVM(resourceId) {
    let resp = await callAzureManagementFunction(
      `${resourceId}/restart?api-version=2022-03-01`,
      "Post"
    );
    if (resp) {
      //let data = await resp.json();
      //return data;
    }
  },
  async startVM(resourceId) {
    let resp = await callAzureManagementFunction(
      `${resourceId}/start?api-version=2022-03-01`,
      "Post"
    );
    if (resp) {
      return resp;
      //let data = await resp.json();
      //return data;
    }
  },

  async deallocateVM(resourceId) {
    let resp = await callAzureManagementFunction(
      `${resourceId}/deallocate?api-version=2022-03-01`,
      "Post"
    );
    if (resp) {
      //let data = await resp.json();
      //return data;
    }
  },

  //////////////////////////////////////////////////////////////////////////////
  // Gets all Hostpools for our subscriptions
  //
  async getAllHostPools() {
    let subscriptions = (await this.getSubscriptions()).value;
    let hostPools = [];
    let resGroups = [];

    let tasks = subscriptions.map((subscription) => {
      return this.getResourceGroups(subscription.subscriptionId);
    });

    let resGroupResults = await Promise.all(tasks);

    resGroupResults.forEach((item) => {
      if (item) {
        resGroups.push(...item.value);
      }
    });

    tasks = resGroups.map((resGroup) => {
      return this.getHostPoolsWithPath(resGroup.id);
    });

    let hostPoolResults = await Promise.all(tasks);
    hostPoolResults.forEach((item) => {
      if (item) {
        hostPools.push(...item.value);
      }
    });

    return hostPools;
  },

  //////////////////////////////////////////////////////////////////////////////////
  //
  // Logging Workspaces
  //
  /// subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Microsoft.DesktopVirtualization/workspaces?api-version=2021-07-12
  /// `${resourceGroupPath}/providers//Microsoft.OperationalInsights/workspaces?api-version=2021-06-01`
  /// `${resourceGroupPath}/providers/Microsoft.Insights/diagnosticSettings?api-version=2021-05-01-preview`

  //////////////////////////////////////////////////////////////////////////////////
  // Get Diagnostic settings for hostPoolPath
  //
  async GetDignosticSettings(hostPoolPath) {
    let resp = await callAzureManagement(
      `${hostPoolPath}/providers/Microsoft.Insights/diagnosticSettings?api-version=2021-05-01-preview`
    );
    if (resp) {
      let data = await resp.json();
      return data;
    }
  },

  ////////////////////////////////////////////////////////////////////////////////////
  // Get OpInsights Workspace
  //
  async GetLogAnalyticsWorkspace(workspacePath) {
    let resp = await callAzureManagement(
      `${workspacePath}?api-version=2021-06-01`
    );
    if (resp) {
      let data = await resp.json();
      return data;
    }
  },
};

///////////////////////////////////////////////////////////////////////////////
// private functions
//
async function callAzureManagement(apiPath) {
  accessToken = await auth.acquireToken(AZURE_VDI_SCOPES).catch((error)=>{log.Error("Failed to fetch Token",error)})
  let resp = await fetch(`${AZURE_MANAGEMENT_BASE}${apiPath}`, {
    headers: { authorization: `Bearer ${accessToken}` },
  })  
  .catch((error) => {
      log.Error("Error while calling azure management",error);
  });
  // see https://stackoverflow.com/questions/38235715/fetch-reject-promise-and-catch-the-error-if-status-is-not-ok
  if (resp && !resp.ok) {
    throw new Error(
      `Call to ${AZURE_MANAGEMENT_BASE}${apiPath} failed: ${resp.statusText}`
    );
  }

  return resp;
}

async function callAzureManagementFunction(apiPath, verb) {
  accessToken = await auth.acquireToken(AZURE_VDI_SCOPES);

  let resp = await fetch(`${AZURE_MANAGEMENT_BASE}${apiPath}`, {
    method: verb,
    headers: { authorization: `Bearer ${accessToken}` },
  });

  if (!resp.ok) {
    let error = new Error(
      `Call to ${AZURE_MANAGEMENT_BASE}${apiPath} failed: ${resp.statusText} status code: ${resp.status}`
    );
    error.url = `${AZURE_MANAGEMENT_BASE}${apiPath}`;
    error.code = resp.status;
    throw error;
  }

  return resp;
}

async function getNextLink(path) {
  accessToken = await auth.acquireToken(AZURE_VDI_SCOPES);
  let resp = await fetch(path, {
    headers: { authorization: `Bearer ${accessToken}` },
  });

  if (!resp.ok) {
    throw new Error(`Call to ${path} failed: ${resp.statusText}`);
  }
  return resp;
}

async function getAllPages(response) {
  let allData = [];
  let data;
  do {
    data = await response.json();
    allData.push(...data.value);
  } while (data.nextLink && (response = await getNextLink(data.nextLink)));
  return allData;
}
