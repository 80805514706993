import azvirtualdesktop from "../../Services/azure-virtualdesktop";
import log from "../../Services/log";
const state = () => ({
  hostPools: [],
  hostPoolsLoading: false,
});

const mutations = {
  setHostPools(state, hostPools) {
    state.hostPools = hostPools;
  },
  setHostPoolsLoading(state, isLoading) {
    state.hostPoolsLoading = isLoading;
  },
};

const actions = {
  async fetchHostPools({ commit }) {
    if (!state.hostPoolsLoading) {
      log.Action("Fetching Hostpools");
      commit("setHostPoolsLoading", true);
      var hostPools = await azvirtualdesktop.getAllHostPools();
      hostPools.sort((a, b) => {
        let fa = a.name.toLowerCase(),
          fb = b.name.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });
      commit("setHostPools", hostPools);
      commit("setHostPoolsLoading", false);
    }
  },
};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
