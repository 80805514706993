import moment from "moment";
export default {
  async transformToApexTimelineFormat(data) {
    let series = [];
    // get all names
    let computerNames = data
      .map((item) => item.Computer)
      .filter((value, index, self) => self.indexOf(value) === index);
    // create a series for each name
    computerNames.forEach((computerName) => {
      let computerNameRows = data.filter((row) => row.Computer == computerName);
      let seriesEntry = {};
      seriesEntry.name = computerName;
      seriesEntry.data = computerNameRows.map((row) => ({
        x: formatDate(row.TimeGenerated),
        y: Math.trunc(row.CounterValue),
      }));
      series.push(seriesEntry);
    });
    return series;
  },

  async tranformToApexBarData(data) {
    let series = [];
    let seriesEntry = {};
    seriesEntry.data = data.map((row) => ({
      x: row.Computer,
      y: row.CounterValue,
    }));
    series.push(seriesEntry);
    return series;
  },
};

function formatDate(datevalue) {
  return moment(datevalue)
    .local()
    .format("YYYY-MM-DDTHH:mm:ss");
}
