var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-container',{staticClass:"pa-5",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"xs":"12","sm":"12","md":"5","cols":"12"}},[_c('HostPoolsCombobox',{attrs:{"disabled":_vm.isRefreshing},on:{"update":function($event){_vm.selectedHostPool = $event;
            _vm.UpdateData();}}})],1),_c('v-col',{staticClass:"py-0",attrs:{"xs":"12","sm":"12","md":"5","col":"12"}},[_c('v-layout',{attrs:{"align-center":""}},[_c('v-btn',{staticClass:"elevation-2",attrs:{"large":"","color":"green","dark":"","loading":_vm.isRefreshing,"disabled":_vm.isRefreshing},on:{"click":_vm.UpdateData}},[_vm._v(" Refresh ")]),_c('v-switch',{staticClass:"px-5",attrs:{"label":"Use Machine Identifier Tag","color":"white","dark":"","inset":""},model:{value:(_vm.useMachineIdentifierTag),callback:function ($$v) {_vm.useMachineIdentifierTag=$$v},expression:"useMachineIdentifierTag"}})],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"xs":"12","sm":"12","md":"5","cols":"12"}},[_c('apexchart',{attrs:{"type":"bar","width":"100%","height":"250","options":_vm.maxUserChartOptions,"series":_vm.maxUserSeries}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"md":"1","lg":"1","xl":"1","cols":"4"}},[_c('v-select',{attrs:{"items":_vm.daysPickerItems,"solo":"","label":"Pick days range for statistics","disabled":_vm.isRefreshing},model:{value:(_vm.daysSelect),callback:function ($$v) {_vm.daysSelect=$$v},expression:"daysSelect"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('apexchart',{attrs:{"options":_vm.chartOptions,"series":_vm.activeSessionsSeries,"height":"350"}}),_c('apexchart',{attrs:{"options":{
            ..._vm.chartOptions,
            ...{
              title: { text: 'Cpu usage in percent by VDI', align: 'left' },
            },
          },"series":_vm.cpuUsageSeries,"height":"350"}}),_c('apexchart',{attrs:{"options":{
            ..._vm.chartOptions,
            ...{
              title: {
                text: 'Memory usage in percent by VDI',
                align: 'left',
              },
            },
          },"series":_vm.memoryUsageSeries,"height":"350"}})],1)],1)],1),_c('v-snackbar',{attrs:{"multi-line":"","timeout":_vm.snackbarTimeOut},scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c('v-btn',_vm._b({attrs:{"color":"blue","text":""},on:{"click":function($event){_vm.snackbarIsVisible = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackbarIsVisible),callback:function ($$v) {_vm.snackbarIsVisible=$$v},expression:"snackbarIsVisible"}},[_c('h4',{staticClass:"red--text text--lighten-1"},[_vm._v("Error")]),_vm._v(" "+_vm._s(_vm.snackbarText)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }