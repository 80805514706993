<template>
  <v-app>
    <v-app-bar app color="primary" dark>
      <div class="d-flex align-center">
        <v-img
          alt="VDI Logo"
          class="shrink mr-2"
          contain
          src="./assets/vdi-logo.png"
          transition="scale-transition"
          width="50"
        />

        <span class="text-vdi-logo"> VDI - Manager </span>
        <span class="text-version"> {{ appVersion }} </span>
      </div>
      <v-spacer></v-spacer>

      <v-spacer></v-spacer>

      <v-menu bottom min-width="200px" rounded offset-y>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-avatar color="indigo">
              <v-icon dark v-if="!user">
                mdi-account-circle
              </v-icon>
              <img :src="photo" alt="photo" v-else />
            </v-avatar>
          </v-btn>
        </template>
        <v-card>
          <v-list-item-content class="justify-center">
            <div class="mx-auto text-center">
              <v-avatar color="indigo">
                <v-icon dark v-if="!user">
                  mdi-account-circle
                </v-icon>
                <img :src="photo" alt="photo" v-else />
              </v-avatar>
              <div v-if="user">
                <h3>{{ user.idTokenClaims.name }}</h3>
                <p class="text-caption mt-1">
                  {{ user.idTokenClaims.email }}
                </p>
                <v-divider class="my-3"></v-divider>
                <v-btn depressed text @click="signOut">
                  Sign out
                </v-btn>
              </div>
              <div v-else>
                <v-btn depressed text @click="signIn">
                  Sign in
                </v-btn>
              </div>
            </div>
          </v-list-item-content>
        </v-card>
      </v-menu>
    </v-app-bar>

    <v-main>
      <VdiDevices v-if="user" v-show="navItem == 1" />
      <VdiUserSessions v-if="user" v-show="navItem == 0" />
      <VdiUserErrors v-if="user" v-show="navItem == 2" />
      <VdiStatistics v-if="user" v-show="navItem == 3" ref="vdiStatistics" />
    </v-main>

    <v-bottom-navigation
      app
      background-color="primary lighten1"
      dark
      color="yellow"
      :value="navItem"
    >
      <v-btn @click="navItem = 0"
        ><span>Users</span><v-icon>mdi-account</v-icon></v-btn
      >
      <v-btn @click="navItem = 1"
        ><span>Devices</span><v-icon>mdi-devices</v-icon></v-btn
      >

      <v-btn @click="navItem = 2"
        ><span>User Logs</span><v-icon>mdi-account</v-icon></v-btn
      >
      <v-btn
        @click="
          navItem = 3;
          sendRefresh();
        "
        ><span>HostPool Statistics</span><v-icon>mdi-trending-up</v-icon></v-btn
      >
    </v-bottom-navigation>
  </v-app>
</template>

<script>
import auth from "./Services/auth";
import graph from "./Services/graph";
import log from "./Services/log";
//import azvdi from "./Services/azure-virtualdesktop";
import VdiDevices from "./components/VdiDevices.vue";
import VdiUserSessions from "./components/VdiUserSessions.vue";
import VdiUserErrors from "./components/VdiUserErrors.vue";
import VdiStatistics from "./components/VDIStatistics.vue";

export default {
  name: "App",

  components: {
    VdiUserErrors,
    VdiDevices,
    VdiUserSessions,
    VdiStatistics,
  },

  data: () => ({
    user: null,
    photo: null,
    meData: null,
    navItem: 1,
    appVersion: "1.7.0.0",
    //
  }),
  watch: {
    user: async function() {
      log.Info("User changed, reading data");
      if (this.user) {
        this.photo = await graph.getPhoto();
        await this.$store.dispatch("hostpoolservice/fetchHostPools");
      }
      //this.meData = await graph.getMe();
      //log.WriteObjectInfo(this.meData);
    },
  },

  async created() {
    log.Action("configuring azure authentication");
    await auth.configure(this.$config);

    log.Action("try restoring user");
    this.user = auth.user();
    if (!this.user) {
      log.Result("restore not possible");
      log.Action("forcing user to log in");
      await auth.login();
      this.user = auth.user();
    } else {
      log.Result("user restored");
    }
    await this.$store.dispatch("hostpoolservice/fetchHostPools");
  },
  methods: {
    async signIn() {
      //this.user = auth.user();
      await auth.login();
      this.user = auth.user();
    },
    async signOut() {
      auth.logout();
      auth.clearLocal();
      this.user = null;
    },
    sendRefresh() {
      log.Info("sending refresh");
      this.$refs.vdiStatistics.RefreshCharts();
    },
  },
};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Lobster&display=swap");
.text-vdi-logo {
  font-size: 2em;
  font-family: lobster;
  text-shadow: 0px 4px 8px grey;
}
.text-version {
  font-size: 0.7em;
  font-family: lobster;
  text-shadow: 0px 4px 8px grey;
  padding-top: 25px;
}
</style>
